













































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import {
  JOB_COSTING_LOCAL_STORAGE,
  JOB_COSTING_STATUS,
  PRODUCE_PRODUCT_STATUS,
} from "@/models/enums/job-costing.enum";
import localStorageService from "@/services/localStorage.service";
import Vue from "vue";
import moment from "moment";
import MNotificationVue from "@/mixins/MNotification.vue";
import { removeDuplicate } from "@/helpers/common";
import { jobCostingService } from "@/services-v2/job-costing.service";
import { Mode } from "@/models/enums/global.enum";
import { Decimal } from "decimal.js-light";
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_FORMAT_TIME,
  DEFAULT_TIME_FORMAT,
} from "@/models/constant/date.constant";
import { mapActions, mapGetters, mapState } from "vuex";
import { FormModel } from "ant-design-vue";
import {
  JobCostingMeatroomProduceConsumeProductBatchNumberDetail,
  RequestAddBatchConsumeProduct,
  RequestJobCostingMeatroomUpdate,
  ResponseJobCostingMeatroomDetail,
  ResponseJobCostingMeatroomProduceDetail,
  RequestJobCostingMeatroomProduceUpdate,
} from "@/models/interface-v2/job-costing-meatroom.interface";
import { transformDataURL } from "@/helpers/file-reader";
import {
  formatterNumber,
  reverseFormatNumber,
  formatCurrency,
} from "@/validator/globalvalidator";
import {
  DECIMAL_PLACES_QTY,
  DECIMAL_PLACES_CURRENCY,
} from "@/models/constant/global.constant";
import { fileServices } from "@/services-v2/file.service";
import { ResponseInventoryLineBatch } from "@/models/interface-v2/inventory-line-batch.interface";
import { IResidueForm } from "@/store/resource/job-costing-meatroom.resource";

interface Form extends ResponseJobCostingMeatroomDetail {
  etaHour?: string;
  processDate?: string;
}

const POST_PRODUCTS_TYPE = [
  {
    type: "bone",
  },
  {
    type: "fat",
  },
  {
    type: "rump",
  },
  {
    type: "oxtail",
  },
  {
    type: "trim",
  },
];

const ALLOW_STATUS_START_JC: JOB_COSTING_STATUS[] = [
  JOB_COSTING_STATUS.WAREHOUSE_PICKED,
  JOB_COSTING_STATUS.PARTIAL_PROCESS,
  JOB_COSTING_STATUS.PARTIAL_COMPLETED,
];

const ALLOW_CONTINUE_PROCESS: PRODUCE_PRODUCT_STATUS[] = [
  PRODUCE_PRODUCT_STATUS.NEED_PROCESSED,
];

export default Vue.extend({
  name: "JobCostingMeatroomV2",
  components: {
    CSelectTableLocationBatch: () =>
      import(
        /*webpackPrefetch: true */ "@/components/shared/select-table-location-batch/CSelectTableLocationBatch.vue"
      ),
    ModalAddConsumeProduct: () =>
      import(/*webpackPrefetch: true */ "./modals/ModalAddConsumeProduct.vue"),
    ModalConfirmJC: () =>
      import(/*webpackPrefetch: true */ "./modals/ModalConfirmJC.vue"),
    CScale: () =>
      import(/*webpackPrefetch: true */ "@/components/shared/scale/CScale.vue"),
    SelectPostProduct: () =>
      import(
        /*webpackPrefetch: true */ "./meatroom-post-product/PostProduct.vue"
      ),
    SelectResidue: () =>
      import(/*webpackPrefetch: true */ "./meatroom-residue/Residue.vue"),
    CSelectVacuum: () =>
      import(
        /*webpackPrefetch: true */ "@/components/shared/select-vacuum/CSelectVacuum.vue"
      ),
    CSelectTray: () =>
      import(
        /*webpackPrefetch: true */ "@/components/shared/select-tray/CSelectTray.vue"
      ),
    CSelectMeatroomTools: () =>
      import(
        /*webpackPrefetch: true */ "@/components/shared/select-meatroom-tools/CSelectMeatroomTools.vue"
      ),
  },
  mixins: [MNotificationVue],
  data() {
    return {
      DEFAULT_DATE_FORMAT,
      DEFAULT_TIME_FORMAT,
      DEFAULT_DATE_FORMAT_TIME,
      POST_PRODUCTS_TYPE,
      DECIMAL_PLACES_QTY,
      DECIMAL_PLACES_CURRENCY,
      PRODUCE_PRODUCT_STATUS,
      form: {
        branchId: "",
        branchName: "",
        butcherName: "",
        customerId: "",
        customerName: "",
        customerPo: "",
        etaDate: null as string | null,
        id: "",
        notes: "",
        orderDate: null as string | null,
        processIn: null as string | null,
        processOut: null as string | null,
        produce: [] as ResponseJobCostingMeatroomProduceDetail[],
        rfqNumber: "",
        salesName: "",
        status: "",

        etaHour: "",
        processDate: "",
      } as Form,
      jcStart: false,
      modal: {
        batch: {
          show: false,
          data: {
            productCode: "",
          } as any | undefined,
        },
        consume: {
          show: false,
          data: undefined as any | undefined,
        },
        processed: {
          show: false,
          data: undefined as any | undefined,
        },
        scale: {
          show: false,
          data: {} as any,
        },
        confirmJC: {
          show: false,
          data: [] as RequestJobCostingMeatroomProduceUpdate[] | undefined,
        },
      },
      colParentProduct: [] as string[],
      loading: {
        submit: false,
        deleteConsume: false,
      },
    };
  },
  computed: {
    ...mapState({
      storeResiduePlaceholder: (store: any) =>
        store.jobCostingMeatroomStore.placeholder,
    }),
    ...mapGetters({
      getUsername: "authStore/GET_USERNAME",
    }),
    allowToStartJC(): boolean {
      return (
        ALLOW_STATUS_START_JC.includes(
          this.form.status as JOB_COSTING_STATUS
        ) && this.form.totalOutstanding > 0
      );
    },
    isPartialProcess(): boolean {
      return this.$route.query.status === JOB_COSTING_STATUS.PARTIAL_PROCESS;
    },
    isModeCreate(): boolean {
      return this.$route.meta.mode === Mode.CREATE;
    },
    isModeView(): boolean {
      return this.$route.meta.mode === Mode.VIEW;
    },
    disabled() {
      return { form: this.jcStart };
    },
    isInitialQtyValid(): boolean {
      return !!this.form.produce.find((x) => x.isValidInitialQty);
    },
    isWarehousePicked(): boolean {
      return this.$route.query.status === JOB_COSTING_STATUS.WAREHOUSE_PICKED;
    },
    canSubmit(): boolean {
      const prod = this.form.produce.find(
        (x) =>
          x.outOfrangeOutstandingQty ||
          x.wasteQtyIntangible === null ||
          x.wasteQtyIntangible === undefined ||
          x.wasteQtyTangible === null ||
          x.wasteQtyTangible === undefined ||
          x.outstandingQty === null ||
          x.outstandingQty === undefined
      );
      if (prod) return true;
      return false;
    },
    allowToAddConsumeProduct(): boolean {
      return ALLOW_STATUS_START_JC.includes(
        this.form.status as JOB_COSTING_STATUS
      );
    },
  },
  watch: {
    getUsername(newValue: string): void {
      if (newValue && this.isWarehousePicked) {
        this.form.butcherName = newValue;
      }
    },
  },
  created() {
    if (this.$route.params.id) {
      this.form.id = this.$route.params.id;
      this.getDetail(this.form.id);
    }
  },
  beforeDestroy(): void {
    this.resetStoreJobCosting();
  },
  methods: {
    formatterNumber,
    reverseFormatNumber,
    formatCurrency,
    moment,
    ...mapActions({
      resetStoreJobCosting: "jobCostingMeatroomStore/RESET_STORE",
    }),
    allowContinueProcess(status: PRODUCE_PRODUCT_STATUS): boolean {
      return ALLOW_CONTINUE_PROCESS.includes(status);
    },
    showModalScale(
      r,
      col: "finalProduct" | "wasteQtyTangible" | "outstandingQty",
      indexParent = -1,
      indexChild = -1
    ): void {
      this.modal.scale.data = {
        ...r,
        col,
        index: {
          indexChild,
          indexParent,
        },
      };
      this.modal.scale.show = true;
    },
    onScaleSave({ value }): void {
      const { col, index } = this.modal.scale.data;

      /**
       * check which column
       */
      if (col === "finalProduct") {
        if (index.indexChild !== -1 && index.indexParent !== -1) {
          this.form.produce[index.indexParent].finalProduct[
            index.indexChild
          ].qty = value;
          this.onChangeFinalQty(this.form.produce[index.indexParent]);
        }
      } else if (col === "wasteQtyTangible") {
        this.form.produce[index.indexParent].wasteQtyTangible = value;
        this.onchangeWaste(this.form.produce[index.indexParent]);
      } else if (col === "outstandingQty") {
        this.form.produce[index.indexParent].outstandingQty = value;
        this.onchangeOutstandingQty(this.form.produce[index.indexParent]);
      }
    },
    onChangeFinalQty(record): void {
      this.sumTotalQty(record);
    },
    onchangeWaste(record): void {
      this.checkValidInitialQty(record);
      const percent = new Decimal(3).dividedBy(100);
      const max = new Decimal(record.initialWeightQty || 0)
        .times(percent)
        .toNumber();
      record.isWasteReachMax = record.wasteQtyTangible
        ? record.wasteQtyTangible > max
        : false;
    },
    searchByConsume(consumes: any[]): string {
      return consumes
        .map((x) => `code~${x.productCode.split("#")[0]}#S`)
        .join("_OR_");
    },
    onSelectProduct(
      { meta },
      record2,
      col: "productCode" | "productName"
    ): void {
      record2.productId = meta.id;
      record2[col] = col === "productName" ? meta.name : meta.code;
    },
    checkStartedJC(): string | null {
      const time = localStorageService.load(
        JOB_COSTING_LOCAL_STORAGE.PROCESS_IN
      );
      if (this.isModeCreate && time) {
        this.jcStart = true;
        return time;
      }
      return null;
    },
    async fetchImage(filename: string, callback: (e) => void): Promise<void> {
      try {
        if (!filename) return;
        const blob = await fileServices.getFile(filename);
        const img = await transformDataURL(blob);
        callback(img);
      } catch (error) {
        this.showErrorMessage("notif_get_attachment_fail");
      }
    },
    async getDetail(id: string): Promise<void> {
      try {
        const res: ResponseJobCostingMeatroomDetail = await jobCostingService.getDetailJobCostingMeatroom(
          id
        );
        this.form = {
          ...res,
          etaHour: res.etaDate, // only take hour from the date
          processDate:
            res.status === JOB_COSTING_STATUS.WAREHOUSE_PICKED
              ? this.checkStartedJC()
              : res.processIn, // only take date from hour
          butcherName:
            res.status === JOB_COSTING_STATUS.WAREHOUSE_PICKED
              ? this.getUsername
              : res.butcherName,
          processIn:
            res.status === JOB_COSTING_STATUS.WAREHOUSE_PICKED
              ? this.checkStartedJC()
              : res.processIn,
        } as ResponseJobCostingMeatroomDetail;
        this.form.produce.forEach((x, i) => {
          x.no = i + 1;

          if (!x.wasteQtyTangible) {
            x.wasteQtyTangible = 0;
          }

          if (!x.wasteQtyIntangible) {
            x.wasteQtyIntangible = 0;
          }

          if (!x.outstandingQty) {
            x.outstandingQty = 0;
          }

          x.finalProduct.forEach((y, j) => {
            y.no = j + 1;

            // fetch image
            this.fetchImage(
              y.processInfo.image || "",
              (e) => (y.processInfo.thumbnail = e)
            );

            // show placeholder
            if (y.productCode === null) {
              y.productCode = undefined;
            }
            if (y.productName === null) {
              y.productName = undefined;
            }
            if (y.uomId === null) {
              y.uomId = undefined;
            }
            if (y.processInfo.trayType === null) {
              y.processInfo.trayType = undefined;
            }
            if (y.processInfo.vacuumType === null) {
              y.processInfo.vacuumType = undefined;
            }
          });

          // count initial qty
          let initialQty = 0;
          let colBatchQty: number[] = [];
          x.consumeProducts.forEach((y, j) => {
            y.no = j + 1;

            if (y.batchNumbers) {
              y.batchNumbers.forEach((z) => {
                colBatchQty.push(z.qty || 0);
              });
            }
          });
          initialQty = colBatchQty.reduce(
            (a, b) => new Decimal(b).plus(a).toNumber(),
            0
          );
          x.initialWeightQty = initialQty;

          // show placeholder
          if (x.differenceReason === null) {
            x.differenceReason = "";
          }
          if (x.machineTool === null) {
            x.machineTool = undefined;
          }
        });
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      }
    },
    async onSaveAddConsumeProduct({ value }): Promise<void> {
      try {
        const payload = {
          consumeId: this.modal.consume.data.consumeId,
          productId: value.productId,
          uomId: value.uomId,
        };
        const newConsume = await jobCostingService.addConsumeProduct(payload);
        newConsume.batchNumbers = newConsume.batchNumbers ?? [];

        this.form.produce.forEach((x) => {
          if (x.id === this.modal.consume.data.id) {
            x.consumeProducts.push(newConsume);
          }
        });

        this.showSuccessMessage("notif_update_success");
      } catch (error) {
        this.showErrorMessage("notif_update_fail");
      }
    },
    showModalProcessed(type, data): void {
      this.modal.processed.data = {
        type,
        data,
      };
      this.modal.processed.show = true;
    },
    async addNewBatch(data: ResponseInventoryLineBatch[]): Promise<void> {
      try {
        const req: RequestAddBatchConsumeProduct[] = data.map((x) => ({
          batchId: x.batchId,
          id: null,
          locationId: x.warehouseLocationId,
          productId: x.product.id,
          qty: x.available,
          uomId: x.uom.id,
        }));

        const newBn = (await jobCostingService.addBatchNumber(
          this.modal.batch.data.consumeId || "",
          req
        )) as JobCostingMeatroomProduceConsumeProductBatchNumberDetail[];

        this.form.produce.forEach((x) => {
          x.consumeProducts.forEach((y) => {
            if (y.consumeId === this.modal.batch.data.consumeId) {
              const oldBn = [...y.batchNumbers];
              y.batchNumbers = [...oldBn, ...newBn];
            }
          });
        });

        this.showSuccessMessage("notif_update_success");
        this.closeModalBatch();
        this.calcInitialQty();
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      }
    },
    onSaveSelectedBatch({
      value,
    }: {
      value: ResponseInventoryLineBatch[];
    }): void {
      let listBatch: ResponseInventoryLineBatch[] = [];
      this.form.produce.forEach((x) => {
        x.consumeProducts.forEach((y) => {
          if (y.productId === this.modal.batch.data.productId) {
            listBatch = [...value];
          }
        });
      });
      this.addNewBatch(listBatch);
    },
    closeModalBatch(): void {
      this.modal.batch.show = false;
    },
    constructRequestUpdate(): RequestJobCostingMeatroomUpdate {
      const { butcherName, processIn, produce } = this.form;
      const payload = {
        butcherName: butcherName || "",
        processIn: processIn || "",
        produce: [] as any,
      };
      const copy = produce.filter((x) => x.isProcessed);
      for (const x of copy) {
        const prodNeedProcess =
          x.finalProduct && x.finalProduct.length
            ? x.finalProduct.filter(
                (y) => y.status === PRODUCE_PRODUCT_STATUS.NEED_PROCESSED
              )
            : null;
        payload.produce.push({
          bone:
            x.bone && x.bone.productId
              ? { productId: x.bone.productId, qty: x.bone.qty, id: null }
              : null,
          consumeId: x.consumeId,
          differenceReason: x.differenceReason,
          fat:
            x.fat && x.fat.productId
              ? { productId: x.fat.productId, qty: x.fat.qty, id: null }
              : null,
          finalProduct:
            prodNeedProcess && prodNeedProcess.length > 0
              ? prodNeedProcess.map((y) => {
                  // copy all except thumbnail
                  const { thumbnail, ...processInfo } = y.processInfo;
                  return {
                    alias: y.alias,
                    id: y.id,
                    processInfo,
                    productId: y.productId,
                    qty: y.qty,
                    uomId: y.uomId,
                  };
                })
              : null,
          oxtail:
            x.oxtail && x.oxtail.productId
              ? { productId: x.oxtail.productId, qty: x.oxtail.qty, id: null }
              : null,
          residue:
            x.residue && x.residue.length
              ? x.residue.map((y) => ({
                  productId: y.productId || "",
                  qty: y.qty,
                  uomId: y.uomId || "",
                  batchNumber: y.batchConsume,
                }))
              : null,
          rump:
            x.rump && x.rump.productId
              ? { productId: x.rump.productId, qty: x.rump.qty, id: null }
              : null,
          totalQty: x.totalQty,
          trim:
            x.trim && x.trim.productId
              ? { productId: x.trim.productId, qty: x.trim.qty, id: null }
              : null,
          wasteQtyIntangible: this.calcWasteIntangible(x),
          wasteQtyTangible: x.wasteQtyTangible,
          outstandingQty: x.outstandingQty,
          initialWeightQty: x.initialWeightQty,
          machineTool: x.machineTool,
        });
      }
      return payload;
    },
    async handleUpdateJobCostingMeatroom(): Promise<void> {
      try {
        this.loading.submit = true;
        const req: RequestJobCostingMeatroomUpdate = this.constructRequestUpdate();
        const res = await jobCostingService.updateJobCostingMeatroom(
          this.form.id,
          req
        );
        if (res.message) await this.showInfoModal(res.message);
        this.showSuccessMessage("notif_update_success");
        localStorageService.remove(JOB_COSTING_LOCAL_STORAGE.PROCESS_IN);
        this.handleBack();
      } catch (error) {
        console.error(error);
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading.submit = false;
      }
    },
    isProduceValid(): [boolean, string] {
      const { produce } = this.form;
      for (const x of produce) {
        if (!x.isProcessed) continue;
        if (!x.machineTool) {
          return [false, "machineTool"];
        }
        for (const y of x.finalProduct) {
          if (!y.productCode || !y.productName) return [false, "product"];
          if (!y.uomId) return [false, "uom"];
          if (y.qty <= 0) return [false, "qty"];
          if (y.processInfo.vacuumOption && !y.processInfo.vacuumType)
            return [false, "vacuum"];
          if (y.processInfo.trayOption && !y.processInfo.trayType)
            return [false, "tray"];
        }
      }
      return [true, ""];
    },
    checkPlaceholderResidue(): boolean {
      const formResidue: IResidueForm = this.storeResiduePlaceholder
        .residueForm;
      const excludeKey: string[] = ["productId", "uom"];
      let invalid = false;
      for (const key in formResidue) {
        if (invalid) break;
        if (
          (!excludeKey.includes(key) && formResidue[key] === undefined) ||
          formResidue[key] === null
        ) {
          invalid = true;
        }
      }
      return invalid;
    },
    validateInitialQty(): { name: string; no: number; valid: boolean } {
      const returnVal = {
        name: "",
        no: 0,
        valid: true
      };
      const { produce } = this.form;
      for (const product of produce) {
        if (!product.isProcessed) continue;
        if (product.initialWeightQty === null || product.initialWeightQty === undefined || product.initialWeightQty <= 0) {
          returnVal.name = product.alias ?? "";
          returnVal.no = product.no ?? 0;
          returnVal.valid = false;
          break;
        }
      }
      return returnVal;
    },
    showModalConfirmJC(): void {
      const validateData = this.validateInitialQty();
      if (!validateData.valid) {
        this.showNotifWarning("notif_job_costing_meat_room_initial_qty_invalid", { name: validateData.name, no: validateData.no });
        return;
      }
      const jobCosting: RequestJobCostingMeatroomUpdate = this.constructRequestUpdate();
      const produces = jobCosting.produce.map((item, index) => {
        return {
          ...item,
          no: index + 1,
        };
      });

      this.modal.confirmJC.show = true;
      this.modal.confirmJC.data = produces;
    },
    handleSubmit(): void {
      this.modal.confirmJC.show = false;

      const theForm = this.$refs.formJobCostingMeatroom as FormModel;
      theForm.validate((valid: boolean) => {
        const [tableValid, column] = this.isProduceValid();
        if (this.checkPlaceholderResidue()) {
          this.showNotifWarning("notif_meatroom_residue_found_placeholder");
        } else if (valid && tableValid) {
          this.handleUpdateJobCostingMeatroom();
        } else {
          const str = {
            machineTool: "lbl_tools",
            vacuum: "lbl_vacuum",
            tray: "lbl_tray",
            qty: "lbl_qty",
            product: "lbl_product",
            uom: "lbl_uom",
          };
          this.showNotifValidationError(
            this.$t("notif_column_required", { data: this.$t(str[column]) })
          );
        }
      });
    },
    handleBack(): void {
      this.$router.push({ name: "sales.transactionsales.jobcosting" });
    },
    onchangeOutstandingQty(record): void {
      if (record.outstandingQty === null || record.outstandingQty === undefined)
        return;
      if (
        record.outstandingQty &&
        (record.outstandingQty >
          new Decimal(this.findMaxOutstandingQty(record.initialWeightQty) || 0)
            .minus(record.totalQty)
            .toNumber() ||
          record.outstandingQty <
            new Decimal(
              this.findMinOutstandingQty(record.initialWeightQty) || 0
            )
              .minus(record.totalQty)
              .toNumber())
      ) {
        record.outOfrangeOutstandingQty = true;
      } else {
        record.outOfrangeOutstandingQty = false;
      }
    },
    /**
     * @param qty initial qty - total qty
     */
    findMaxOutstandingQty(qty: number): number {
      const maxTreshold = new Decimal(3).dividedBy(100);
      const qtyPercentInitial = maxTreshold.times(qty);
      return qtyPercentInitial.plus(qty).toNumber();
    },
    /**
     * @param qty initial qty - total qty
     */
    findMinOutstandingQty(qty: number): number {
      const maxTreshold = new Decimal(3).dividedBy(100);
      const qtyPercentInitial = maxTreshold.times(qty);
      return new Decimal(qty).minus(qtyPercentInitial).toNumber();
    },
    calcWasteIntangible(e): number {
      return new Decimal(e.initialWeightQty || 0)
        .minus(e.totalQty || 0)
        .minus(e.wasteQtyTangible || 0)
        .minus(e.outstandingQty || 0)
        .toNumber();
    },
    onDataChangePostProduct({ data, type }, item): void {
      item[type] = data;
      this.sumTotalQty(item);
    },
    checkValidInitialQty(record): void {
      record.isValidInitialQty =
        record.initialWeightQty ===
        new Decimal(record.totalQty || 0)
          .plus(record.wasteQtyTangible || 0)
          .toNumber();
    },
    sumTotalQty(record): void {
      let finalQty = 0;
      if (record.finalProduct?.length) {
        finalQty = record.finalProduct
          .map((x) => x.qty)
          .reduce((a, b) => new Decimal(b || 0).plus(a).toNumber(), 0);
      }
      const cQty: number[] = [
        finalQty,
        ...record.residue.map((x) => x.qty),
        record.bone?.qty || 0,
        record.fat?.qty || 0,
        record.rump?.qty || 0,
        record.oxtail?.qty || 0,
        record.trim?.qty || 0,
      ];
      record.totalQty = cQty.reduce(
        (a, b) => new Decimal(b || 0).plus(a).toNumber(),
        0
      );
      this.checkValidInitialQty(record);
    },
    onDataChangeResidue({ data }, item): void {
      item.residue = [...data];
      this.sumTotalQty(item);
    },
    async returnBatch(record3, record2): Promise<void> {
      try {
        await jobCostingService.returnBatchNumber(record3.id);
        this.showSuccessMessage("notif_update_success");
        const listBN = [...record2.batchNumbers];
        record2.batchNumbers = listBN.filter(
          (x) => x.batchId !== record3.batchId
        );
        this.calcInitialQty();
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      }
    },
    async deleteConsumeProduct(
      consumeId: string,
      productId: string,
      produceId: string
    ): Promise<void> {
      try {
        if (!consumeId || !productId) return;
        this.loading.deleteConsume = true;
        await jobCostingService.deleteConsumeProduct(consumeId, productId);
        const res = await jobCostingService.getDetailJobCostingMeatroom(
          this.form.id
        );
        const idxResProduce = res.produce.findIndex((p) => p.id === produceId);
        const idxFormProduce = this.form.produce.findIndex(
          (p) => p.id === produceId
        );
        if (idxResProduce !== -1) {
          this.form.produce[idxFormProduce].consumeProducts =
            res.produce[idxResProduce].consumeProducts;
        }
        this.showSuccessMessage("notif_update_success");
        this.calcInitialQty();
      } catch (error) {
        this.showErrorMessage("notif_update_fail");
      } finally {
        this.loading.deleteConsume = false;
      }
    },
    showModalAddConsumeProduct(e): void {
      this.modal.consume.data = e;
      const { consumeProducts } = e;
      let colProduct: string[] = [];
      consumeProducts.forEach((x) => {
        colProduct.push(x.productCode.split("#")[0]);
      });
      let trimed = removeDuplicate(colProduct);
      this.colParentProduct = trimed;
      this.modal.consume.show = true;
    },
    showModalChooseBatch(record, consumeId: string): void {
      this.modal.batch.show = true;
      this.modal.batch.data = record;
      this.modal.batch.data.consumeId = consumeId;
    },
    startJobCosting(): void {
      this.showConfirmation().then((val: boolean) => {
        if (val) {
          this.jcStart = true;
          localStorageService.save(
            JOB_COSTING_LOCAL_STORAGE.PROCESS_IN,
            this.moment().format()
          );
          this.form.processIn = this.moment().format();
          this.form.processDate = this.moment().format();
        }
      });
    },
    async confirmDeleteConsume(
      consumeId: string,
      productId: string,
      produceId: string
    ): Promise<void> {
      const confirm = await this.showConfirmation("lbl_confirm_delete");
      if (!confirm) return;
      this.deleteConsumeProduct(consumeId, productId, produceId);
    },
    async confirmReturnBatch(batchs, consume): Promise<void> {
      const confirm = await this.showConfirmation("lbl_confirm_return_batch");
      if (!confirm) return;
      this.returnBatch(batchs, consume);
    },
    calcInitialQty(): void {
      let colBatchQty: number[] = [];
      this.form.produce.forEach((produce) => {
        produce.consumeProducts.forEach((consume) => {
          if (consume.batchNumbers && consume.batchNumbers.length) {
            consume.batchNumbers.forEach((bn) => colBatchQty.push(bn.qty ?? 0));
          }
        });
        produce.initialWeightQty = colBatchQty.reduce(
          (a, b) => new Decimal(b ?? 0).plus(a).toNumber(),
          0
        );
        colBatchQty = []; // reset for each produce product
      });
    },
  },
});
