var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-card",
        { attrs: { title: _vm.$t("lbl_job_costing_meatroom_process") } },
        [
          _c("span", { attrs: { slot: "extra" }, slot: "extra" }, [
            _vm._v(_vm._s(_vm.form.status || "-"))
          ]),
          _vm.allowToStartJC
            ? _c(
                "a-alert",
                {
                  attrs: {
                    message: _vm.jcStart ? "" : _vm.$t("lbl_warning"),
                    type: _vm.jcStart ? "success" : "warning",
                    "show-icon": ""
                  }
                },
                [
                  _c("template", { slot: "description" }, [
                    _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.jcStart
                              ? _vm.$t("lbl_job_costing_started")
                              : _vm.$t("lbl_you_have_not_start_job_costing")
                          ) +
                          " "
                      )
                    ]),
                    _c(
                      "div",
                      {},
                      [
                        !_vm.jcStart
                          ? _c(
                              "a-button",
                              {
                                attrs: { block: "", type: "primary" },
                                on: { click: _vm.startJobCosting }
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("lbl_start")) + " ")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                ],
                2
              )
            : _vm._e(),
          _c(
            "a-card-grid",
            { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
            [
              _c(
                "a-form-model",
                {
                  ref: "formJobCostingMeatroom",
                  attrs: {
                    model: _vm.form,
                    "label-align": "left",
                    layout: "horizontal"
                  }
                },
                [
                  _c(
                    "a-row",
                    { attrs: { gutter: [16, 16], type: "flex" } },
                    [
                      _c("a-col", { attrs: { span: 24 } }, [
                        _c("p", { staticClass: "text-subtitle-1 mb-0" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("lbl_customer_detail")) + " "
                          )
                        ])
                      ]),
                      _c(
                        "a-col",
                        { attrs: { sm: 24, md: 12, lg: 8, xl: 6 } },
                        [
                          _c(
                            "a-form-model-item",
                            { attrs: { label: _vm.$t("lbl_customer_name") } },
                            [
                              _c("span", { staticClass: "ant-form-text" }, [
                                _vm._v(_vm._s(_vm.form.customerName || "-"))
                              ])
                            ]
                          ),
                          _c(
                            "a-form-model-item",
                            {
                              attrs: { label: _vm.$t("lbl_customer_po_number") }
                            },
                            [
                              _c("span", { staticClass: "ant-form-text" }, [
                                _vm._v(_vm._s(_vm.form.customerPo || "-"))
                              ])
                            ]
                          ),
                          _c(
                            "a-form-model-item",
                            { attrs: { label: _vm.$t("lbl_order_date") } },
                            [
                              _c("span", { staticClass: "ant-form-text" }, [
                                _vm._v(
                                  _vm._s(_vm._f("date")(_vm.form.orderDate))
                                )
                              ])
                            ]
                          ),
                          _c(
                            "a-form-model-item",
                            { attrs: { label: _vm.$t("lbl_eta_date") } },
                            [
                              _c("span", { staticClass: "ant-form-text" }, [
                                _vm._v(_vm._s(_vm._f("date")(_vm.form.etaDate)))
                              ])
                            ]
                          ),
                          _c(
                            "a-form-model-item",
                            { attrs: { label: _vm.$t("lbl_eta_hour") } },
                            [
                              _c("span", { staticClass: "ant-form-text" }, [
                                _vm._v(_vm._s(_vm._f("time")(_vm.form.etaHour)))
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { sm: 24, md: 12, lg: 8, xl: 6 } },
                        [
                          _c("p", { staticClass: "text-subtitle-1 mb-0" }, [
                            _vm._v(
                              " " + _vm._s(_vm.$t("lbl_order_detail")) + " "
                            )
                          ]),
                          _c(
                            "a-form-model-item",
                            { attrs: { label: _vm.$t("lbl_branch") } },
                            [
                              _c("span", { staticClass: "ant-form-text" }, [
                                _vm._v(_vm._s(_vm.form.branchName || "-"))
                              ])
                            ]
                          ),
                          _c(
                            "a-form-model-item",
                            {
                              attrs: { label: _vm.$t("lbl_sales_person_name") }
                            },
                            [
                              _c("span", { staticClass: "ant-form-text" }, [
                                _vm._v(_vm._s(_vm.form.salesName || "-"))
                              ])
                            ]
                          ),
                          _c(
                            "a-form-model-item",
                            { attrs: { label: _vm.$t("lbl_rfq_number") } },
                            [
                              _c("span", { staticClass: "ant-form-text" }, [
                                _vm._v(_vm._s(_vm.form.rfqNumber || "-"))
                              ])
                            ]
                          ),
                          _c(
                            "a-form-model-item",
                            { attrs: { label: _vm.$t("lbl_notes") } },
                            [
                              _c("span", { staticClass: "ant-form-text" }, [
                                _vm._v(_vm._s(_vm.form.notes || "-"))
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { sm: 24, md: 12, lg: 8, xl: 6 } },
                        [
                          _c("p", { staticClass: "text-subtitle-1 mb-0" }, [
                            _vm._v(
                              " " + _vm._s(_vm.$t("lbl_process_detail")) + " "
                            )
                          ]),
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_process_date"),
                                prop: "processDate"
                              }
                            },
                            [
                              _vm.isModeCreate
                                ? [
                                    _c("a-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder: _vm.$t("lbl_choose"),
                                        format: _vm.DEFAULT_DATE_FORMAT,
                                        disabled: ""
                                      },
                                      model: {
                                        value: _vm.form.processDate,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "processDate", $$v)
                                        },
                                        expression: "form.processDate"
                                      }
                                    })
                                  ]
                                : _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("date")(_vm.form.processDate)
                                        ) +
                                        " "
                                    )
                                  ])
                            ],
                            2
                          ),
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_butcher_name"),
                                prop: "butcherName"
                              }
                            },
                            [
                              _c("a-input", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  "allow-clear": "",
                                  placeholder: _vm.$t("lbl_type_here"),
                                  "read-only": ""
                                },
                                model: {
                                  value: _vm.form.butcherName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "butcherName", $$v)
                                  },
                                  expression: "form.butcherName"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_process_in"),
                                prop: "processIn"
                              }
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm._f("time")(_vm.form.processIn))
                                )
                              ])
                            ]
                          ),
                          _c(
                            "a-form-model-item",
                            { attrs: { label: _vm.$t("lbl_process_out") } },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm._f("time")(_vm.form.processOut))
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-card-grid",
            { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
            [
              _c(
                "a-table",
                {
                  attrs: {
                    "data-source": _vm.form.produce,
                    "row-key": function(r, i) {
                      return r.no
                    },
                    pagination: {
                      showTotal: function(total) {
                        return _vm.$t("lbl_total_items", { total: total })
                      }
                    }
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "expandedRowRender",
                      fn: function(record) {
                        return [
                          _c(
                            "a-form-model",
                            [
                              _c(
                                "a-row",
                                { attrs: { gutter: [16, 16], type: "flex" } },
                                [
                                  _c(
                                    "a-col",
                                    { attrs: { sm: 24, md: 12, lg: 6, xl: 6 } },
                                    [
                                      _c(
                                        "a-form-model-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("lbl_qty_order")
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("toDecimalQty")(
                                                  record.orderQty
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-col",
                                    { attrs: { sm: 24, md: 12, lg: 6, xl: 6 } },
                                    [
                                      _c(
                                        "a-form-model-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("lbl_process")
                                          }
                                        },
                                        [
                                          record.isProcessed
                                            ? _c("a-icon", {
                                                attrs: {
                                                  type: "check-circle",
                                                  theme: "twoTone",
                                                  "two-tone-color": "#52c41a"
                                                }
                                              })
                                            : _c("a-icon", {
                                                attrs: {
                                                  type: "close-circle",
                                                  theme: "twoTone",
                                                  "two-tone-color": "#f5222d"
                                                }
                                              })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-col",
                                    { attrs: { sm: 24, md: 12, lg: 6, xl: 6 } },
                                    [
                                      _c(
                                        "a-form-model-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("lbl_condition")
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(record.condition || "-") +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-col",
                                    { attrs: { sm: 24, md: 12, lg: 6, xl: 6 } },
                                    [
                                      _c(
                                        "a-form-model-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("lbl_invoice_weight")
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                record.invoiceWeight || "-"
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-col",
                                    { attrs: { sm: 24, md: 12, lg: 6, xl: 6 } },
                                    [
                                      _c(
                                        "a-form-model-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("lbl_tools"),
                                            required: record.isProcessed
                                          }
                                        },
                                        [
                                          _vm.isModeCreate
                                            ? _c("CSelectMeatroomTools", {
                                                attrs: {
                                                  disabled:
                                                    !_vm.disabled.form ||
                                                    !record.isProcessed
                                                },
                                                model: {
                                                  value: record.machineTool,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      record,
                                                      "machineTool",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "record.machineTool"
                                                }
                                              })
                                            : _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    record.machineTool || "-"
                                                  )
                                                )
                                              ]),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: !record.machineTool,
                                                  expression:
                                                    "!record.machineTool"
                                                }
                                              ],
                                              staticClass: "validate-error"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "lbl_validation_required_error"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("a-divider"),
                          _c(
                            "a-row",
                            { attrs: { gutter: [16, 16], type: "flex" } },
                            [
                              _c("a-col", { attrs: { span: 24 } }, [
                                _c(
                                  "p",
                                  { staticClass: "text-subtitle-1 mb-0" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("lbl_process_info")) +
                                        " "
                                    )
                                  ]
                                )
                              ]),
                              _c(
                                "a-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "a-table",
                                    {
                                      attrs: {
                                        "data-source": record.finalProduct,
                                        pagination: {
                                          showTotal: function(total) {
                                            return _vm.$t("lbl_total_items", {
                                              total: total
                                            })
                                          }
                                        },
                                        scroll: {
                                          y: 520,
                                          x: "calc(2300px + 50%)"
                                        },
                                        "row-key": function(e, j) {
                                          return j
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "a-table-column",
                                        {
                                          key: "no",
                                          attrs: {
                                            width: "60px",
                                            "data-index": "no"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(text, ref) {
                                                  var no = ref.no
                                                  return _c("span", {}, [
                                                    _vm._v(
                                                      " " + _vm._s(no) + " "
                                                    )
                                                  ])
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              attrs: { slot: "title" },
                                              slot: "title"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("lbl_number_short")
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "a-table-column",
                                        {
                                          key: "alias",
                                          attrs: { "data-index": "alias" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(text, record2) {
                                                  return [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          record2.alias || "-"
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              attrs: { slot: "title" },
                                              slot: "title"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "lbl_invoice_description"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "a-table-column",
                                        {
                                          key: "productCode",
                                          attrs: {
                                            "data-index": "productCode"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(text, record2) {
                                                  return [
                                                    _vm.allowContinueProcess(
                                                      record2.status
                                                    )
                                                      ? _c(
                                                          "CSelectMasterProductCode",
                                                          {
                                                            attrs: {
                                                              "prop-search-by": _vm.searchByConsume(
                                                                record.consumeProducts
                                                              ),
                                                              "is-disabled": !_vm
                                                                .disabled.form
                                                            },
                                                            on: {
                                                              "on-select": function(
                                                                ref
                                                              ) {
                                                                var meta =
                                                                  ref.meta

                                                                return _vm.onSelectProduct(
                                                                  meta,
                                                                  record2,
                                                                  "productName"
                                                                )
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                record2.productCode,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  record2,
                                                                  "productCode",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "record2.productCode"
                                                            }
                                                          }
                                                        )
                                                      : _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              record2.productCode ||
                                                                "-"
                                                            )
                                                          )
                                                        ]),
                                                    _c(
                                                      "div",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value: !record2.productCode,
                                                            expression:
                                                              "!record2.productCode"
                                                          }
                                                        ],
                                                        staticClass:
                                                          "validate-error"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "lbl_validation_required_error"
                                                              )
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              class: {
                                                "is-required":
                                                  record.isProcessed
                                              },
                                              attrs: { slot: "title" },
                                              slot: "title"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("lbl_product_code")
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "a-table-column",
                                        {
                                          key: "productName",
                                          attrs: {
                                            "data-index": "productName"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(text, record2) {
                                                  return [
                                                    _vm.allowContinueProcess(
                                                      record2.status
                                                    )
                                                      ? _c(
                                                          "CSelectMasterProductName",
                                                          {
                                                            attrs: {
                                                              "prop-search-by": _vm.searchByConsume(
                                                                record.consumeProducts
                                                              ),
                                                              "is-disabled":
                                                                !_vm.disabled
                                                                  .form ||
                                                                record2.status ===
                                                                  _vm
                                                                    .PRODUCE_PRODUCT_STATUS
                                                                    .READY_TO_MEATROOM
                                                            },
                                                            on: {
                                                              "on-select": function(
                                                                ref
                                                              ) {
                                                                var meta =
                                                                  ref.meta

                                                                return _vm.onSelectProduct(
                                                                  meta,
                                                                  record2,
                                                                  "productCode"
                                                                )
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                record2.productName,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  record2,
                                                                  "productName",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "record2.productName"
                                                            }
                                                          }
                                                        )
                                                      : _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              record2.productName ||
                                                                "-"
                                                            )
                                                          )
                                                        ]),
                                                    _c(
                                                      "div",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value: !record2.productName,
                                                            expression:
                                                              "!record2.productName"
                                                          }
                                                        ],
                                                        staticClass:
                                                          "validate-error"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "lbl_validation_required_error"
                                                              )
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              class: {
                                                "is-required":
                                                  record.isProcessed
                                              },
                                              attrs: { slot: "title" },
                                              slot: "title"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("lbl_product_name")
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "a-table-column",
                                        {
                                          key: "uomId",
                                          attrs: {
                                            "data-index": "uomId",
                                            width: "110px"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(text, record2) {
                                                  return [
                                                    _vm.allowContinueProcess(
                                                      record2.status
                                                    )
                                                      ? _c("CSelectUomConv", {
                                                          attrs: {
                                                            "is-disabled":
                                                              !_vm.disabled
                                                                .form ||
                                                              record2.status ===
                                                                _vm
                                                                  .PRODUCE_PRODUCT_STATUS
                                                                  .READY_TO_MEATROOM,
                                                            "prop-product-id":
                                                              record2.productId ||
                                                              ""
                                                          },
                                                          model: {
                                                            value:
                                                              record2.uomId,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                record2,
                                                                "uomId",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "record2.uomId"
                                                          }
                                                        })
                                                      : _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              record2.uom || "-"
                                                            )
                                                          )
                                                        ]),
                                                    _c(
                                                      "div",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value: !record2.uomId,
                                                            expression:
                                                              "!record2.uomId"
                                                          }
                                                        ],
                                                        staticClass:
                                                          "validate-error"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "lbl_validation_required_error"
                                                              )
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              class: {
                                                "is-required":
                                                  record.isProcessed
                                              },
                                              attrs: { slot: "title" },
                                              slot: "title"
                                            },
                                            [_vm._v(_vm._s(_vm.$t("lbl_uom")))]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "a-table-column",
                                        {
                                          key: "qty",
                                          attrs: { "data-index": "qty" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(text, record2) {
                                                  return [
                                                    _c(
                                                      "a-space",
                                                      [
                                                        _c("a-input-number", {
                                                          staticStyle: {
                                                            width: "100%"
                                                          },
                                                          attrs: {
                                                            formatter:
                                                              _vm.formatterNumber,
                                                            parser:
                                                              _vm.reverseFormatNumber,
                                                            disabled:
                                                              !_vm.disabled
                                                                .form ||
                                                              !_vm.allowContinueProcess(
                                                                record2.status
                                                              ),
                                                            precision:
                                                              _vm.DECIMAL_PLACES_QTY,
                                                            min: 0,
                                                            placeholder: _vm.$t(
                                                              "lbl_type_here"
                                                            )
                                                          },
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              return _vm.onChangeFinalQty(
                                                                record
                                                              )
                                                            }
                                                          },
                                                          model: {
                                                            value: record2.qty,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                record2,
                                                                "qty",
                                                                _vm._n($$v)
                                                              )
                                                            },
                                                            expression:
                                                              "record2.qty"
                                                          }
                                                        }),
                                                        _c(
                                                          "a-button",
                                                          {
                                                            attrs: {
                                                              type: "dashed",
                                                              disabled:
                                                                !_vm.disabled
                                                                  .form ||
                                                                !_vm.allowContinueProcess(
                                                                  record2.status
                                                                )
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.showModalScale(
                                                                  record2,
                                                                  "finalProduct",
                                                                  record.no - 1,
                                                                  record2.no - 1
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "lbl_scale"
                                                                  )
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              record2.qty <=
                                                                0 ||
                                                              !record2.qty,
                                                            expression:
                                                              "record2.qty <= 0 || !record2.qty"
                                                          }
                                                        ],
                                                        staticClass:
                                                          "validate-error"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "lbl_validation_required_error"
                                                              )
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              class: {
                                                "is-required":
                                                  record.isProcessed
                                              },
                                              attrs: { slot: "title" },
                                              slot: "title"
                                            },
                                            [_vm._v(_vm._s(_vm.$t("lbl_qty")))]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "a-table-column",
                                        {
                                          key: "trayType",
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(text, record2) {
                                                  return [
                                                    record2.processInfo
                                                      .trayOption
                                                      ? [
                                                          _vm.isModeCreate
                                                            ? _c(
                                                                "CSelectTray",
                                                                {
                                                                  attrs: {
                                                                    disabled: !_vm
                                                                      .disabled
                                                                      .form
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      record2
                                                                        .processInfo
                                                                        .trayType,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        record2.processInfo,
                                                                        "trayType",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "record2.processInfo.trayType"
                                                                  }
                                                                }
                                                              )
                                                            : _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    record2
                                                                      .processInfo
                                                                      .trayType ||
                                                                      "-"
                                                                  )
                                                                )
                                                              ]),
                                                          _c(
                                                            "div",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value:
                                                                    !record2
                                                                      .processInfo
                                                                      .trayType &&
                                                                    record2
                                                                      .processInfo
                                                                      .trayOption,
                                                                  expression:
                                                                    "\n                          !record2.processInfo.trayType &&\n                            record2.processInfo.trayOption\n                        "
                                                                }
                                                              ],
                                                              staticClass:
                                                                "validate-error"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "lbl_validation_required_error"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      : _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t("lbl_no")
                                                            )
                                                          )
                                                        ])
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              class: {
                                                "is-required":
                                                  record.isProcessed
                                              },
                                              attrs: { slot: "title" },
                                              slot: "title"
                                            },
                                            [_vm._v(_vm._s(_vm.$t("lbl_tray")))]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "a-table-column",
                                        {
                                          key: "vacuumType",
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(text, record2) {
                                                  return [
                                                    record2.processInfo
                                                      .vacuumOption
                                                      ? [
                                                          _vm.isModeCreate
                                                            ? _c(
                                                                "CSelectVacuum",
                                                                {
                                                                  attrs: {
                                                                    disabled: !_vm
                                                                      .disabled
                                                                      .form
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      record2
                                                                        .processInfo
                                                                        .vacuumType,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        record2.processInfo,
                                                                        "vacuumType",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "record2.processInfo.vacuumType"
                                                                  }
                                                                }
                                                              )
                                                            : _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    record2
                                                                      .processInfo
                                                                      .vacuumType ||
                                                                      "-"
                                                                  )
                                                                )
                                                              ]),
                                                          _c(
                                                            "div",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value:
                                                                    !record2
                                                                      .processInfo
                                                                      .vacuumType &&
                                                                    record2
                                                                      .processInfo
                                                                      .vacuumOption,
                                                                  expression:
                                                                    "\n                          !record2.processInfo.vacuumType &&\n                            record2.processInfo.vacuumOption\n                        "
                                                                }
                                                              ],
                                                              staticClass:
                                                                "validate-error"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "lbl_validation_required_error"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      : _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t("lbl_no")
                                                            )
                                                          )
                                                        ])
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              class: {
                                                "is-required":
                                                  record.isProcessed
                                              },
                                              attrs: { slot: "title" },
                                              slot: "title"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("lbl_vacuum"))
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "a-table-column",
                                        {
                                          key: "batchNumber",
                                          attrs: {
                                            "data-index": "batchNumber"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(text, record2) {
                                                  return [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          record2.batchNumber ||
                                                            "-"
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              attrs: { slot: "title" },
                                              slot: "title"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("lbl_batch_number")
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "a-table-column",
                                        {
                                          key: "cutting",
                                          attrs: {
                                            "data-index": "processInfo.cutting"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(text, record2) {
                                                  return [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          record2.processInfo
                                                            .cutting || "-"
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              attrs: { slot: "title" },
                                              slot: "title"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("lbl_cutting"))
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "a-table-column",
                                        {
                                          key: "packQty",
                                          attrs: {
                                            "data-index": "processInfo.packQty"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(text, record2) {
                                                  return [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          record2.processInfo
                                                            .packQty || "-"
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              attrs: { slot: "title" },
                                              slot: "title"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("lbl_qty_pack"))
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "a-table-column",
                                        {
                                          key: "processNote",
                                          attrs: {
                                            "data-index":
                                              "processInfo.processNote"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(text, record2) {
                                                  return [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          record2.processInfo
                                                            .processNote || "-"
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              attrs: { slot: "title" },
                                              slot: "title"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("lbl_process_notes")
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "a-table-column",
                                        {
                                          key: "size",
                                          attrs: {
                                            "data-index": "processInfo.size"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(text, record2) {
                                                  return [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          record2.processInfo
                                                            .size || "-"
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              attrs: { slot: "title" },
                                              slot: "title"
                                            },
                                            [_vm._v(_vm._s(_vm.$t("lbl_size")))]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "a-table-column",
                                        {
                                          key: "sticker",
                                          attrs: {
                                            "data-index": "processInfo.sticker"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(text, record2) {
                                                  return [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          record2.processInfo
                                                            .sticker || "-"
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              attrs: { slot: "title" },
                                              slot: "title"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("lbl_sticker"))
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "a-table-column",
                                        {
                                          key: "image",
                                          attrs: {
                                            "data-index":
                                              "processInfo.thumbnail"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(text, record2) {
                                                  return record2.processInfo
                                                    .image
                                                    ? _c("figure", {}, [
                                                        _c("img", {
                                                          attrs: {
                                                            src:
                                                              record2
                                                                .processInfo
                                                                .thumbnail,
                                                            alt: text,
                                                            width: "150px",
                                                            height: "auto"
                                                          }
                                                        })
                                                      ])
                                                    : _c(
                                                        "span",
                                                        [
                                                          _c("a-icon", {
                                                            attrs: {
                                                              type: "file"
                                                            }
                                                          }),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "lbl_file_not_found"
                                                                )
                                                              )
                                                          )
                                                        ],
                                                        1
                                                      )
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              attrs: { slot: "title" },
                                              slot: "title"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("lbl_image"))
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("a-divider"),
                          _c(
                            "a-row",
                            { attrs: { gutter: [16, 16], type: "flex" } },
                            [
                              _c("a-col", { attrs: { span: 12 } }, [
                                _c(
                                  "p",
                                  { staticClass: "text-subtitle-1 mb-0" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("lbl_consumed_product")) +
                                        " "
                                    )
                                  ]
                                )
                              ]),
                              _vm.allowToAddConsumeProduct
                                ? _c(
                                    "a-col",
                                    { attrs: { span: 12, align: "end" } },
                                    [
                                      _c(
                                        "a-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            icon: "plus",
                                            disabled:
                                              !_vm.disabled.form ||
                                              !record.isProcessed
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.showModalAddConsumeProduct(
                                                record
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "lbl_add_consume_product"
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "a-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "a-table",
                                    {
                                      attrs: {
                                        "data-source": record.consumeProducts,
                                        pagination: {
                                          showTotal: function(total) {
                                            return _vm.$t("lbl_total_items", {
                                              total: total
                                            })
                                          }
                                        },
                                        scroll: { y: 320 },
                                        "row-key": function(f, k) {
                                          return k
                                        }
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "expandedRowRender",
                                            fn: function(record2) {
                                              return [
                                                _c(
                                                  "a-table",
                                                  {
                                                    attrs: {
                                                      "data-source":
                                                        record2.batchNumbers,
                                                      pagination: false,
                                                      "row-class-name": function(
                                                        r
                                                      ) {
                                                        return r.batchNumber
                                                          ? ""
                                                          : "d-none"
                                                      },
                                                      "row-key": function(
                                                        g,
                                                        l
                                                      ) {
                                                        return l
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "a-table-column",
                                                      {
                                                        key: "batchNumber",
                                                        attrs: {
                                                          "data-index":
                                                            "batchNumber"
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function(
                                                                text,
                                                                record3
                                                              ) {
                                                                return [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        record3.batchNumber ||
                                                                          "-"
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            attrs: {
                                                              slot: "title"
                                                            },
                                                            slot: "title"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "lbl_batch_number"
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "a-table-column",
                                                      {
                                                        key: "qty",
                                                        attrs: {
                                                          "data-index": "qty",
                                                          width: "100px"
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function(
                                                                text,
                                                                record3
                                                              ) {
                                                                return [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "toDecimalQty"
                                                                        )(
                                                                          record3.qty
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            attrs: {
                                                              slot: "title"
                                                            },
                                                            slot: "title"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "lbl_qty"
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "a-table-column",
                                                      {
                                                        key: "uom",
                                                        attrs: {
                                                          "data-index": "uom",
                                                          width: "100px"
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function(
                                                                text,
                                                                record3
                                                              ) {
                                                                return [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        record3.uom ||
                                                                          "-"
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            attrs: {
                                                              slot: "title"
                                                            },
                                                            slot: "title"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "lbl_uom"
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "a-table-column",
                                                      {
                                                        key: "locationName",
                                                        attrs: {
                                                          "data-index":
                                                            "locationName"
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function(
                                                                text,
                                                                record3
                                                              ) {
                                                                return [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        record3.locationName ||
                                                                          "-"
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            attrs: {
                                                              slot: "title"
                                                            },
                                                            slot: "title"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "lbl_location"
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _vm.isModeCreate
                                                      ? _c(
                                                          "a-table-column",
                                                          {
                                                            key: "action",
                                                            attrs: {
                                                              align: "center"
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "default",
                                                                  fn: function(
                                                                    text,
                                                                    record3
                                                                  ) {
                                                                    return [
                                                                      record3.batchId
                                                                        ? _c(
                                                                            "a-button",
                                                                            {
                                                                              attrs: {
                                                                                icon:
                                                                                  "rollback",
                                                                                type:
                                                                                  "danger",
                                                                                disabled:
                                                                                  !_vm
                                                                                    .disabled
                                                                                    .form ||
                                                                                  !record.isProcessed
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.confirmReturnBatch(
                                                                                    record3,
                                                                                    record2
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "lbl_return_batch"
                                                                                    )
                                                                                  ) +
                                                                                  " "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e()
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                attrs: {
                                                                  slot: "title"
                                                                },
                                                                slot: "title"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "lbl_action"
                                                                    )
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _c(
                                        "a-table-column",
                                        {
                                          key: "no",
                                          attrs: {
                                            width: "50px",
                                            "data-index": "no"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(text, record2) {
                                                  return _c("span", {}, [
                                                    _vm._v(_vm._s(record2.no))
                                                  ])
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              attrs: { slot: "title" },
                                              slot: "title"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("lbl_number_short")
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "a-table-column",
                                        {
                                          key: "productCode",
                                          attrs: {
                                            "data-index": "productCode"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(text, record2) {
                                                  return [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          record2.productCode ||
                                                            "-"
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              attrs: { slot: "title" },
                                              slot: "title"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("lbl_product_code")
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "a-table-column",
                                        {
                                          key: "productName",
                                          attrs: {
                                            "data-index": "productName"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(text, record2) {
                                                  return [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          record2.productName ||
                                                            "-"
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              attrs: { slot: "title" },
                                              slot: "title"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("lbl_product_name")
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm.allowToStartJC
                                        ? _c(
                                            "a-table-column",
                                            {
                                              key: "action",
                                              attrs: { align: "center" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(
                                                      text,
                                                      record2
                                                    ) {
                                                      return [
                                                        _c(
                                                          "a-button",
                                                          {
                                                            attrs: {
                                                              icon: "plus",
                                                              type: "primary",
                                                              disabled:
                                                                !_vm.disabled
                                                                  .form ||
                                                                !record.isProcessed
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.showModalChooseBatch(
                                                                  record2,
                                                                  record.consumeId
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "lbl_add_batch"
                                                                  )
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        ),
                                                        _c("a-divider", {
                                                          attrs: {
                                                            type: "vertical"
                                                          }
                                                        }),
                                                        _c(
                                                          "a-button",
                                                          {
                                                            attrs: {
                                                              icon: "delete",
                                                              type: "danger",
                                                              disabled:
                                                                !_vm.disabled
                                                                  .form ||
                                                                !record.isProcessed,
                                                              loading:
                                                                _vm.loading
                                                                  .deleteConsume
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.confirmDeleteConsume(
                                                                  record.consumeId,
                                                                  record2.productId,
                                                                  record.id
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "lbl_delete"
                                                                  )
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  attrs: { slot: "title" },
                                                  slot: "title"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("lbl_action"))
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("a-divider"),
                          _c("SelectResidue", {
                            attrs: {
                              "prop-detail-document": record.residue,
                              "prop-consume-product": record.consumeProducts,
                              disabled:
                                !_vm.disabled.form || !record.isProcessed
                            },
                            on: {
                              "on-data-change": function(e) {
                                return _vm.onDataChangeResidue(e, record)
                              }
                            }
                          }),
                          _c("a-divider"),
                          _vm._l(_vm.POST_PRODUCTS_TYPE, function(postProduct) {
                            return _c("SelectPostProduct", {
                              key: postProduct.type,
                              staticClass: "mt-4",
                              attrs: {
                                "prop-item": postProduct,
                                disabled:
                                  !_vm.disabled.form || !record.isProcessed,
                                "prop-detail-document": record[postProduct.type]
                              },
                              on: {
                                "on-data-change": function(e) {
                                  return _vm.onDataChangePostProduct(e, record)
                                }
                              }
                            })
                          }),
                          _c("a-divider"),
                          _c(
                            "a-row",
                            { attrs: { gutter: [16, 16], type: "flex" } },
                            [
                              _c("a-col", { attrs: { span: 24 } }, [
                                _c(
                                  "table",
                                  {
                                    staticStyle: { width: "500px" },
                                    attrs: {
                                      celspacing: "50px",
                                      "aria-describedby": "summary table line"
                                    }
                                  },
                                  [
                                    _c("thead", [
                                      _c("th", { attrs: { colspan: "2" } })
                                    ]),
                                    _c("tbody", [
                                      _c("tr", [
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(_vm.$t("lbl_initial_qty")) +
                                              ":"
                                          )
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("toDecimalQty")(
                                                  record.initialWeightQty
                                                )
                                              ) +
                                              " "
                                          )
                                        ])
                                      ]),
                                      _c("tr", [
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("lbl_total_processed")
                                            ) + ":"
                                          )
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("toDecimalQty")(
                                                  record.totalQty || 0
                                                )
                                              ) +
                                              " "
                                          )
                                        ])
                                      ]),
                                      _c("tr", [
                                        _c("td", { staticClass: "align-top" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("lbl_waste_tangible")
                                              ) +
                                              ": "
                                          )
                                        ]),
                                        _c(
                                          "td",
                                          [
                                            _vm.isModeCreate
                                              ? [
                                                  _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "a-space",
                                                        [
                                                          _c("a-input-number", {
                                                            staticStyle: {
                                                              width: "250px"
                                                            },
                                                            attrs: {
                                                              placeholder: _vm.$t(
                                                                "lbl_type_here"
                                                              ),
                                                              disabled:
                                                                !_vm.disabled
                                                                  .form ||
                                                                !record.isProcessed,
                                                              formatter:
                                                                _vm.formatterNumber,
                                                              parser:
                                                                _vm.reverseFormatNumber,
                                                              min: 0,
                                                              precision:
                                                                _vm.DECIMAL_PLACES_QTY
                                                            },
                                                            on: {
                                                              change: function() {
                                                                return _vm.onchangeWaste(
                                                                  record
                                                                )
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                record.wasteQtyTangible,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  record,
                                                                  "wasteQtyTangible",
                                                                  _vm._n($$v)
                                                                )
                                                              },
                                                              expression:
                                                                "record.wasteQtyTangible"
                                                            }
                                                          }),
                                                          _c(
                                                            "a-button",
                                                            {
                                                              attrs: {
                                                                type: "dashed",
                                                                disabled:
                                                                  !_vm.disabled
                                                                    .form ||
                                                                  !record.isProcessed
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.showModalScale(
                                                                    record,
                                                                    "wasteQtyTangible",
                                                                    record.no -
                                                                      1
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "lbl_scale"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "p",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            (record.isProcessed &&
                                                              record.wasteQtyTangible ===
                                                                null) ||
                                                            record.wasteQtyTangible ===
                                                              undefined,
                                                          expression:
                                                            "\n                            (record.isProcessed &&\n                              record.wasteQtyTangible === null) ||\n                              record.wasteQtyTangible === undefined\n                          "
                                                        }
                                                      ],
                                                      staticClass:
                                                        "validate-error mb-0"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "lbl_validation_required_error"
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "p",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            record.isProcessed &&
                                                            !record.isValidInitialQty,
                                                          expression:
                                                            "\n                            record.isProcessed && !record.isValidInitialQty\n                          "
                                                        }
                                                      ],
                                                      staticClass:
                                                        "validate-warning mb-0"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "lbl_validation_initial_qty"
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "p",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            record.isProcessed &&
                                                            record.isWasteReachMax,
                                                          expression:
                                                            "\n                            record.isProcessed && record.isWasteReachMax\n                          "
                                                        }
                                                      ],
                                                      staticClass:
                                                        "validate-warning mb-0"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "lbl_waste_reach_maximum"
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              : _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("toDecimalQty")(
                                                        record.wasteQtyTangible
                                                      )
                                                    )
                                                  )
                                                ])
                                          ],
                                          2
                                        )
                                      ]),
                                      _c("tr", [
                                        _c(
                                          "td",
                                          { staticClass: "align-top" },
                                          [
                                            _c(
                                              "a-tooltip",
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "title" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "lbl_formula_waste_intangible"
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                ),
                                                _c("a-icon", {
                                                  attrs: { type: "info-circle" }
                                                }),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "lbl_waste_intangible"
                                                      )
                                                    ) +
                                                    ": "
                                                )
                                              ],
                                              2
                                            )
                                          ],
                                          1
                                        ),
                                        _c("td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("toDecimalQty")(
                                                  _vm.calcWasteIntangible(
                                                    record
                                                  )
                                                )
                                              ) +
                                              " "
                                          )
                                        ])
                                      ]),
                                      _c("tr", [
                                        _c("td", { staticClass: "align-top" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("lbl_outstanding_qty")
                                              ) +
                                              ": "
                                          )
                                        ]),
                                        _c(
                                          "td",
                                          [
                                            _vm.isModeCreate
                                              ? [
                                                  _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "a-space",
                                                        [
                                                          _c("a-input-number", {
                                                            staticStyle: {
                                                              width: "250px"
                                                            },
                                                            attrs: {
                                                              placeholder: _vm.$t(
                                                                "lbl_type_here"
                                                              ),
                                                              disabled:
                                                                !_vm.disabled
                                                                  .form ||
                                                                !record.isProcessed,
                                                              formatter:
                                                                _vm.formatterNumber,
                                                              parser:
                                                                _vm.reverseFormatNumber,
                                                              min: 0,
                                                              precision:
                                                                _vm.DECIMAL_PLACES_QTY
                                                            },
                                                            on: {
                                                              change: function() {
                                                                return _vm.onchangeOutstandingQty(
                                                                  record
                                                                )
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                record.outstandingQty,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  record,
                                                                  "outstandingQty",
                                                                  _vm._n($$v)
                                                                )
                                                              },
                                                              expression:
                                                                "record.outstandingQty"
                                                            }
                                                          }),
                                                          _c(
                                                            "a-button",
                                                            {
                                                              attrs: {
                                                                type: "dashed",
                                                                disabled:
                                                                  !_vm.disabled
                                                                    .form ||
                                                                  !record.isProcessed
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.showModalScale(
                                                                    record,
                                                                    "outstandingQty",
                                                                    record.no -
                                                                      1
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "lbl_scale"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "p",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            (record.isProcessed &&
                                                              record.outstandingQty ===
                                                                null) ||
                                                            record.outstandingQty ===
                                                              undefined,
                                                          expression:
                                                            "\n                            (record.isProcessed &&\n                              record.outstandingQty === null) ||\n                              record.outstandingQty === undefined\n                          "
                                                        }
                                                      ],
                                                      staticClass:
                                                        "validate-error mb-0"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "lbl_validation_required_error"
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "p",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            record.isProcessed &&
                                                            record.outOfrangeOutstandingQty,
                                                          expression:
                                                            "\n                            record.isProcessed &&\n                              record.outOfrangeOutstandingQty\n                          "
                                                        }
                                                      ],
                                                      staticClass:
                                                        "validate-error mb-0"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "lbl_validation_outstanding_qty_out_of_range"
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              : _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("toDecimalQty")(
                                                        record.outstandingQty
                                                      )
                                                    )
                                                  )
                                                ])
                                          ],
                                          2
                                        )
                                      ]),
                                      _c("tr", [
                                        _c("td", { staticClass: "align-top" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("lbl_difference_reason")
                                              ) +
                                              ": "
                                          )
                                        ]),
                                        _c(
                                          "td",
                                          { staticStyle: { width: "200px" } },
                                          [
                                            _c("a-textarea", {
                                              attrs: {
                                                placeholder: _vm.$t(
                                                  "lbl_type_here"
                                                ),
                                                disabled: !record.isProcessed,
                                                "read-only": !_vm.disabled.form,
                                                "auto-size": {
                                                  minRows: 1,
                                                  maxRows: 4
                                                },
                                                "allow-clear": ""
                                              },
                                              model: {
                                                value: record.differenceReason,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    record,
                                                    "differenceReason",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "record.differenceReason"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ])
                                    ])
                                  ]
                                )
                              ])
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _c(
                    "a-table-column",
                    { key: "no", attrs: { "data-index": "no" } },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_number_short")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "alias",
                      attrs: { "data-index": "alias" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text) {
                            return _c("span", {}, [_vm._v(_vm._s(text || "-"))])
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_invoice_description")))
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-card-grid",
            { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
            [
              _c(
                "a-row",
                { attrs: { gutter: [16, 16], type: "flex", justify: "end" } },
                [
                  _c(
                    "a-col",
                    [
                      _c(
                        "a-space",
                        [
                          _c("a-button", { on: { click: _vm.handleBack } }, [
                            _vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")
                          ]),
                          _vm.isModeCreate
                            ? _c(
                                "a-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    icon: "save",
                                    loading: _vm.loading.submit,
                                    disabled:
                                      (_vm.canSubmit && !_vm.jcStart) ||
                                      !_vm.disabled.form
                                  },
                                  on: { click: _vm.showModalConfirmJC }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("lbl_submit")) + " "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            "destroy-on-close": true,
            width: 850,
            footer: null,
            title:
              _vm.$t("lbl_consumed_product") +
              " - " +
              _vm.modal.batch.data.productCode
          },
          model: {
            value: _vm.modal.batch.show,
            callback: function($$v) {
              _vm.$set(_vm.modal.batch, "show", $$v)
            },
            expression: "modal.batch.show"
          }
        },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16], type: "flex" } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c("CSelectTableLocationBatch", {
                    attrs: {
                      "prop-product-code": _vm.modal.batch.data.productCode
                    },
                    on: {
                      "on-save": _vm.onSaveSelectedBatch,
                      "on-close": function($event) {
                        _vm.modal.batch.show = false
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("ModalAddConsumeProduct", {
        attrs: { "prop-parent-product": _vm.colParentProduct },
        on: { "on-save": _vm.onSaveAddConsumeProduct },
        model: {
          value: _vm.modal.consume.show,
          callback: function($$v) {
            _vm.$set(_vm.modal.consume, "show", $$v)
          },
          expression: "modal.consume.show"
        }
      }),
      _c("ModalConfirmJC", {
        attrs: { products: _vm.modal.confirmJC.data },
        on: { "on-save": _vm.handleSubmit },
        model: {
          value: _vm.modal.confirmJC.show,
          callback: function($$v) {
            _vm.$set(_vm.modal.confirmJC, "show", $$v)
          },
          expression: "modal.confirmJC.show"
        }
      }),
      _vm.modal.scale.show
        ? _c("CScale", {
            on: { "on-save": _vm.onScaleSave },
            model: {
              value: _vm.modal.scale.show,
              callback: function($$v) {
                _vm.$set(_vm.modal.scale, "show", $$v)
              },
              expression: "modal.scale.show"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }